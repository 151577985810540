import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Box,
  Step,
  StepLabel,
  Stepper,
  Button,
  Typography,
  Grid,
  Divider
} from '@mui/material';
import atlanticQuestion from
  '@lba-dev/package.local-globals/atlanticQuestion';
import SectionPrensenter from './SectionPresenter';
import ShowMore from '../ShowMore';
import notifSystem from '../../../../notifSystem';
import { sortedGroupedValueByKey } from '../../../../utils/sortedHelpers';

const tempHide = true;
const methodCalculQuestion = atlanticQuestion.deperditionData[0];
// const isolationQuestion = atlanticQuestion.deperditionData[0].cond[
//   methodCalculQuestion.options[3]
// ].at(1);
const getQuestion = atlanticQuestion.utils.getQuestion;
const occRegexQuestion = /[1-9]-[a-z]/;

/**
 * @param {{
 * element: {
 * questions: QuestionMetier[]
 * },
 * onValidate: () => void,
 * } & import('react').PropsWithChildren} props
 */
function StepsPrensenter(props) {
  const [activeStep, setActiveStep] = useState(0);
  /**
   * @type {Record<string | undefined, QuestionMetier[]>}
   */
  const pagesGrouped = props.element.questions.groupBy('page');

  const pages = useMemo(
    () => Object.keys(pagesGrouped)
      .filter(e => e !== 'undefined')
      .sort()
    , [pagesGrouped]
  );

  /**
   * @param {QuestionMetier} question
   */
  const evaluateQuestion = useCallback((question) => {
    /**
     * @param {QuestionMetier} qt
     */
    const evaluateResponses = (qt) => qt.responses
      .every(response => {
        if (response.response === props.answers.getIn([qt.question])) {
          return (response.subQuestions || [])
            .every(subQuestion => evaluateQuestion(subQuestion));
        }
        return true;
      });
    const answers = Object.keys(props.answers.toJS());
    const answer = answers.find(a => a.includes(question.question));
    const match = answer?.match(occRegexQuestion);

    // Check if there's a direct answer
    if (!match) {
      const result = getQuestion(question.question)?.required ?
        !!props.answers.getIn([question.question]) : true;
      if (!result) {
        notifSystem.error(
          'Questions non complétées',
          `Veuillez renseigner la question "${question.question}"`
        );
        return false;
      }

      if (!question.responses.length) {
        return result;
      }
      return evaluateResponses(question);
    }

    if (!question.responses.length) {
      return getQuestion(answer)?.required
        ? !!props.answers.getIn([answer]) : true;
    }
    return evaluateResponses(question);
  }, [props.answers]);

  const isStepCompleted = useCallback(
    () => pagesGrouped[pages[activeStep]]
      .every(q => evaluateQuestion(q)),
    [pagesGrouped, pages, activeStep, evaluateQuestion]
  );

  const handleNext = useCallback(() => {
    if (!isStepCompleted()) {
      return;
    }

    if (
      props.isDevis &&
      !props.hasAddress &&
      props.answers.getIn([methodCalculQuestion.label]) ===
      methodCalculQuestion.options.at(3)
    ) {
      notifSystem.error(
        'Adresse non complétée',
        'Veuillez renseigner une adresse.'
      );
      const input = document.querySelector('#adressClientDevis');
      if (input) {
        input.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setTimeout(() => input.focus(), 500);
      }
      return;
    }

    if (
      props.isDevis &&
      props.answers.hasIn([methodCalculQuestion.label]) &&
      props.answers.getIn([methodCalculQuestion.label]) ===
      methodCalculQuestion.options.at(3) &&
      activeStep === 0
    ) {
      props.onValidate();
    }

    if (activeStep === pages.length - 1) {
      props.onValidate();
      return;
    }
    setActiveStep(s => s + 1);
  }, [
    methodCalculQuestion,
    activeStep,
    pages.length,
    props.answers,
    props.hasAddress,
    isStepCompleted,
    props.onValidate
  ]
  );

  const handleBack = useCallback(() => {
    if (activeStep === 0) {
      return;
    }
    setActiveStep(s => s - 1);
  }, [activeStep]);

  const getSortedSectionGrouped = useCallback(
    (data) => sortedGroupedValueByKey(
      data.groupBy('section'),
      'sectionPosition'
    ), []
  );

  useEffect(() => {
    if (
      props.isDevis &&
      props.hasAddress &&
      pages.length > 0 &&
      props.element
        .questions
        .find(e => methodCalculQuestion.label === e.question)
    ) {
      props.onValidate(false);
    }
  }, []);

  if (pages.length === 0) {
    return <SectionPrensenter
      tempHide={tempHide}
      {...props}
    />;
  }

  return <Box sx={{ width: '100%' }}>
    {
      Object.keys(props.element.questions.groupBy('section')).length &&
      <Typography
        sx={{ mb: 2 }}
        variant="h6"
        children="Questions pour le client"
      />
    }
    <Stepper activeStep={activeStep}>
      {pages.map((page, pageIndex) => (
        <Step key={`step-${pageIndex}`}>
          <StepLabel>Page {page}</StepLabel>
        </Step>
      ))}
    </Stepper>
    <Fragment>
      <SectionPrensenter
        tempHide={tempHide}
        sectionGrouped={
          getSortedSectionGrouped(pagesGrouped[pages[activeStep]])
        }
        {...props}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          variant='outlined'
          color='inherit'
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Retour
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          variant='contained'
          color='success'
          onClick={handleNext}
        >
          {
            activeStep === pages.length - 1 ?
              'Valider les informations' : 'Suivant'
          }
        </Button>
      </Box>
    </Fragment>
    <Divider sx={{ my: 2 }} />
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Typography
          variant="h6"
          component="h5"
          children="Discours commercial"
        />
      </Grid>
      {!tempHide && <Grid item xs={4}>
        <ShowMore
          element={{ audio: props.element.audio }}
          text={props.element.mailText}
          title="Discours commercial"
        />
      </Grid>}
      <Grid item xs={10}>
        <Typography children={props.element.mailText} />
      </Grid>
    </Grid>
  </Box>;
}

export default memo(StepsPrensenter);
