/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import ShowMore from '../ShowMore';
import {
  MSQ_DET,
  MSQ_UT,
  MSQ_CL
} from '@lba-dev/package.local-globals/questionTypeMSteps';
import { withStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import {
  AnswerBoolean,
  AnswerDuo,
  FreeTextField,
  GenDropdown,
  GenPopperImage,
  GenQuestion,
  GenSuggest,
  MouseHoverPopover,
  reOrderResp
} from './components';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { mapDeepQuestionWithBehavior } from '../../../../utils/function';

const alpha = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'
];

const renderSubQuestion = ({
  classes,
  index,
  s,
  newAnswers,
  restart,
  setAnswers,
  countImportant,
  element,
  user,
  infoComps,
  subQuestions,
  question,
  numOcc,
  ActionButton,
  ...rest
}) =>
  <Grid
    container
    alignItems="center"
    columnSpacing={2}
    marginLeft={2}
  >
    <Grid item xs={1} />
    <Grid item sx={s.infobulle ? {
      display: 'flex',
      alignItems: 'center',
      columnGap: 0.5
    } : {}}
    >
      <Typography
        className={classes.subQuestions}
        children={`${index + 1}. ${s.question}`} />
      {s.infobulle && <MouseHoverPopover content={s.infobulle} />}
    </Grid>

    <Grid item>
      <GenDropdown
        responses={reOrderResp(s.responses, s)}
        newAnswers={newAnswers}
        elem={s}
        classes={classes}
        setAnswers={setAnswers}
        metierElement={element}
        user={user}
        restart={restart}
        countImportant={countImportant}
        subQuestions={subQuestions}
        question={question}
        numOcc={numOcc}
        isSub />
    </Grid>
    <GenSuggest
      responses={s.responses}
      setAnswers={setAnswers}
      infoComps={infoComps}
      newAnswers={newAnswers}
      restart={restart}
      countImportant={countImportant}
      question={s}
      metierElement={element}
      text="informations complémentaires"
      user={user}
      numOcc={numOcc}
      isSub
      {...rest} />
    {s.maxDuplicate > 0 &&
      ActionButton
    }
  </Grid>;
/**
 * @param {{
 * question: QuestionMetier, responses: ResponseMetier[],
 * subQuestions: QuestionMetier[]
 * }} props
 */
function renderQuiz({
  i,
  classes,
  responses,
  question,
  rest,
  newAnswers,
  restart,
  setAnswers,
  countImportant,
  element,
  user,
  infoComps,
  subQuestions,
  ActionButton
}) {
  const numOcc = isNaN(i) ? i : '';
  return <Box
    component="div"
    sx={{
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between'
    }}
  >
    <Grid container className={classes.gridContainer}
      spacing={1}
      alignItems="center"
    >
      <GenQuestion
        index={i}
        responses={responses}
        question={question}
        {...rest} />
      {question.infobulle && <MouseHoverPopover content={question.infobulle} />}
      {[MSQ_DET, MSQ_UT].includes(question.questionType) &&
        (responses || []).length > 0 &&
        responses.some((s) => s.important) &&
        <Typography children="*" color="red" />}
      <AnswerBoolean
        question={question}
        newAnswers={newAnswers}
        classes={classes}
        restart={restart}
        setAnswers={setAnswers}
        numOcc={numOcc}
      />
      {[MSQ_DET, MSQ_UT].includes(question.questionType) &&
        (responses || []).length > 0
        ? [
          ...(responses.length <= 2
            ? responses.map((r, i) => <AnswerDuo
              key={i}
              question={question}
              newAnswers={newAnswers}
              classes={classes}
              restart={restart}
              setAnswers={setAnswers}
              countImportant={countImportant}
              allResponses={responses}
              response={r}
              numOcc={numOcc}
            />
            ) : [
              <Grid item key={0}>
                <GenDropdown
                  responses={reOrderResp(responses, question)}
                  newAnswers={newAnswers}
                  elem={question}
                  classes={classes}
                  setAnswers={setAnswers}
                  metierElement={element}
                  user={user}
                  restart={restart}
                  countImportant={countImportant}
                  numOcc={numOcc}
                />
              </Grid>
            ]),
          <GenPopperImage
            key={1}
            responses={responses}
            newAnswers={newAnswers}
            question={question} />,
          <GenSuggest
            key={2}
            responses={responses}
            setAnswers={setAnswers}
            infoComps={infoComps}
            newAnswers={newAnswers}
            restart={restart}
            countImportant={countImportant}
            question={question}
            metierElement={element}
            user={user}
            text="informations complémentaires"
            numOcc={numOcc}
            {...rest} />
        ] : ''}
      {subQuestions?.length > 0
        ? subQuestions.map((s, index) => {
          const responsesSub = s.responses.filter(e => e.response);
          const questionName = s.maxDuplicate > 0
            ? `${index + 1}-${alpha[0]} ${s.question}`
            : s.question;
          const subQuestionsSub = responsesSub
            ?.find(r => r.response === newAnswers.get(questionName))
            ?.subQuestions;

          return <>
            <Grid key={index} container alignItems="center"
              columnSpacing={1}>
              <Grid item xs={1} />
              <Grid item sx={s.infobulle ? {
                display: 'flex',
                alignItems: 'center',
                columnGap: 0.5
              } : {}}
              >
                <Typography
                  className={classes.subQuestions}
                  children={`${index + 1}. ${s.question}`} />
                {s.infobulle && <MouseHoverPopover content={s.infobulle} />}
              </Grid>

              <Grid item>
                <GenDropdown
                  responses={reOrderResp(s.responses, s)}
                  newAnswers={newAnswers}
                  elem={s}
                  classes={classes}
                  setAnswers={setAnswers}
                  metierElement={element}
                  user={user}
                  restart={restart}
                  countImportant={countImportant}
                  subQuestions={subQuestions}
                  question={question}
                  numOcc={numOcc}
                  isSub />
              </Grid>
              <GenSuggest
                responses={s.responses}
                setAnswers={setAnswers}
                infoComps={infoComps}
                newAnswers={newAnswers}
                restart={restart}
                countImportant={countImportant}
                question={s}
                metierElement={element}
                text="informations complémentaires"
                user={user}
                numOcc={numOcc}
                isSub
                {...rest} />
              {s.maxDuplicate > 0 &&
                ActionButton
              }
            </Grid>
            {
              subQuestionsSub?.length > 0 &&
              subQuestionsSub.map((e, iSub) => {
                const responsesSubSub = s.responses.filter(e => e.response);
                const questionNameSub = s.maxDuplicate > 0
                  ? `${iSub + 1}-${alpha[0]} ${s.question}`
                  : s.question;
                const subQuestionsSubSub = responsesSubSub
                  ?.find(r => r.response === newAnswers.get(questionNameSub))
                  ?.subQuestions;
                return renderSubQuestion({
                  index: iSub,
                  classes,
                  s: e,
                  newAnswers,
                  restart,
                  setAnswers,
                  countImportant,
                  element,
                  user,
                  infoComps,
                  subQuestions: subQuestionsSubSub,
                  ActionButton: null,
                  numOcc: ''
                })
              })
            }
          </>
        }
        ) : ''}
      {question.questionType === MSQ_CL &&
        <FreeTextField
          question={question}
          setAnswers={setAnswers}
          numOcc={numOcc}
          restart={restart}
          answers={newAnswers} />}
    </Grid>
    {question.maxDuplicate > 0 &&
      ActionButton
    }
  </Box>;
}

const styles = {
  gridContainer: {
    margin: 0,
  },
  chip: {
    fontSize: 10,
    width: '100%',
    height: 20,
    padding: 0,
    marginRight: 4,
    borderRadius: 4
  },
  chipDropDown: {
    fontSize: 10,
    height: 20,
    padding: 0,
    marginRight: 4,
    borderRadius: 4
  },
  subQuestions: {
    whiteSpace: 'nowrap',
    fontSize: 15,
    lineHeight: '2',
  },
  acInput: {
    height: 20,
  },
  aCExpanded: {
    minWidth: 200,
  },
  multiACExpanded: {
    minWidth: 240,
  },
};

/**
 * @param {{ element: { questions: QuestionMetier[] }}} props
 */
const ClientHelps = ({
  element: { attachements, audio, ...element },
  answers: newAnswers,
  infoComps,
  setAnswers,
  restart,
  classes,
  ...rest
}) => {
  const [questionOcc, setQuestionOcc] = useState({});
  const user = useSelector(({ users, userId }) =>
    users.find(e => e._id === userId)
  );

  const countImportant = (element.questions || []).reduce((acc, curr) => {
    acc +=
      [MSQ_DET, MSQ_UT].includes(curr.questionType) &&
        (curr.responses || []).length &&
        curr.responses.some((s) => s.important) ? 1 : 0;
    return acc;
  }, 0);

  useEffect(() => {
    const behavior = (question, qi) => {
      if (question.maxDuplicate > 0) {
        setQuestionOcc((prev) => ({
          ...prev,
          [question.question]: []
        }));
      }
      if (question.defaultValue && !newAnswers.has(question.question)) {
        if (question.maxDuplicate > 0) {
          setAnswers(
            [`${qi + 1}-${alpha[0]} ${question.question}`],
            question.defaultValue,
            [question]
          );
        } else {
          setAnswers([question.question], question.defaultValue, [question]);
        }
      }
    }
    mapDeepQuestionWithBehavior(element.questions, behavior);
  }, []);

  /**
   * @param {QuestionMetier} question
   */
  const duplicateQuestion = (question) => {
    if ((questionOcc[question.question]?.length || 0) < question.maxDuplicate) {
      setQuestionOcc((prev) => ({
        ...prev,
        [question.question]: prev[question.question]
          ? [...prev[question.question], question]
          : [question]
      }));
    }
  }

  /**
   * @param {QuestionMetier} question
   * @param {number} index
   * @param {string} [prefix]
   */
  const removeQuestion = (question, index, prefix) => {
    const newQOcc = questionOcc[question.question]
      .filter((_, i) => i !== index);
    setQuestionOcc((prev) => ({
      ...prev,
      [question.question]: newQOcc
    }));

    if (prefix && isNaN(prefix)) {
      setAnswers([`${prefix} ${question.question}`], null);
      question.responses?.forEach(response => {
        response.subQuestions?.forEach(subQuestion => {
          setAnswers([`${prefix} ${subQuestion.question}`], null);
        });
      })
    }
  };

  return (
    <Grid container spacing={2}>
      {!rest.tempHide && [
        <Grid key={0} item xs={8}>
          <Typography variant="h6" component="h5" children={'Bon à savoir'} />
        </Grid>,
        <Grid key={1} item xs={4}>
          <ShowMore
            element={{ attachements }}
            text={element.help}
            title="Bon à savoir"
          />
        </Grid>
      ]}
      <Grid item xs={12}>
        {rest.showDetails && (
          <Typography
            variant="h6"
            component="h5"
            children="Questions pour le client"
          />
        )}
        {(element.questions || []).map((e, i) => {
          const responses = e.responses.filter(e => e.response);
          const questionName = e.maxDuplicate > 0
            ? `${i + 1}-${alpha[0]} ${e.question}`
            : e.question;
          const subQuestions = responses
            ?.find(r => r.response === newAnswers.get(questionName))
            ?.subQuestions;

          const occQuestions = questionOcc[e.question];

          const ActionButton = <IconButton
            title="Dupliquer la question"
            sx={{ justifyContent: 'flex-end' }}
            disabled={
              (occQuestions?.length || 0) >= e.maxDuplicate
            }
            onClick={() => duplicateQuestion(e)}
          >
            <Add color='inherit' />
          </IconButton>

          return <>
            {
              !occQuestions && renderQuiz({
                i,
                classes,
                responses, question: e,
                rest,
                newAnswers,
                restart,
                setAnswers,
                countImportant,
                element,
                user,
                infoComps,
                subQuestions,
                ActionButton
              })
            }
            {
              occQuestions && renderQuiz({
                i: `${i + 1}-${alpha[0]}`,
                classes,
                responses, question: e,
                rest,
                newAnswers,
                restart,
                setAnswers,
                countImportant,
                element,
                user,
                infoComps,
                subQuestions,
                ActionButton
              })
            }
            {
              occQuestions?.length > 0 &&
              occQuestions?.map((qOcc, qOccI) => {
                const prefix = `${i + 1}-${alpha[qOccI + 1]}`;
                const ActionBtn = <IconButton
                  title="Supprimer la question"
                  sx={{ justifyContent: 'flex-end' }}
                  onClick={() => removeQuestion(qOcc, qOccI, prefix)}
                >
                  <Delete color='error' />
                </IconButton>

                return renderQuiz({
                  i: prefix,
                  classes,
                  responses,
                  question: qOcc,
                  rest,
                  newAnswers,
                  restart,
                  setAnswers,
                  countImportant,
                  element,
                  user,
                  infoComps,
                  subQuestions,
                  ActionButton: ActionBtn,
                  questionOcc
                })
              })
            }
            <Divider />
          </>
        })}
      </Grid>
      {
        rest.showDetails && (
          <>
            <Grid item xs={8}>
              <Typography
                variant="h6"
                component="h5"
                children="Discours commercial"
              />
            </Grid>
            {!rest.tempHide && <Grid item xs={4}>
              <ShowMore
                element={{ audio }}
                text={element.mailText}
                title="Discours commercial"
              />
            </Grid>}
            {
              element.mailText && <Grid item xs={10}>
                <Typography children={element.mailText} />
              </Grid>
            }
          </>
        )
      }
    </Grid >
  );
};

export default withStyles(ClientHelps, styles);

