import {
  Call,
  Add,
  Sms,
  Delete,
  DateRange,
  Cancel,
  CheckCircle,
  HowToReg,
  PhotoCamera,
  Voicemail,
  SupportAgent,
  EventAvailable,
} from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';

import {
  D_NT,
  D_AR,
  D_REF,
} from '@lba-dev/package.local-globals/demandeStatus';

import {
  cancelDemande,
  callChangeStatus,
  callAppel,
  transferTo,
  displayRelanceDialog,
  showSMS,
  reminderAction,
  sendMail,
  callAction,
  generateCampaignsToSend,
  sendMeetingMailandSMS
} from '../../../actions/demande';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import { openAssignDialog, demandePhoto } from '../../../actions/general';
import { displaySoundsFromRingover } from '../../../actions/ringover';

const demandeActions = [
  {
    name: 'Appel',
    icon: Call,
    action: callAppel,
    customVisible: (elem, props) =>
      props.selected && props.selected.client && props.selected.client.tel1,
  },
  {
    name: 'Demander une photo',
    icon: PhotoCamera,
    customVisible: (elem, props) => props.selected?.status === D_NT,
    action: (props, demande) => {
      demandePhoto('Demande de photo', {
        tel: demande?.client?.tel1,
        categorie: demande.categorie,
        zipCode: demande?.client?.zipCode,
        demandeId: demande._id,
        userId: props.userId,
      })
    },
  },
  {
    name: 'SMS de relance',
    icon: Sms,
    action: displayRelanceDialog(),
  },
  {
    name: 'Mail de relance',
    icon: Sms,
    action: displayRelanceDialog('mail'),
  },
  {
    name: 'SMS',
    icon: Sms,
    action: showSMS,
  },
  {
    name: 'Envoyer une Demande de RDV',
    icon: EventAvailable,
    customVisible: (elem, props) =>
      props.selected &&
      ['Leroy Merlin Trezor'].includes(props.selected.source),
    action: sendMeetingMailandSMS,
  },
  {
    name: (props) =>
      props.selected && props.selected.devis && props.selected.devis.id
        ? 'Modifier le devis'
        : 'Créer un devis',
    icon: Add,
    link: (elem) => transferTo('devis', true)({ selected: elem }),
    action: transferTo('devis'),
  },
  {
    customVisible: (elem, props) =>
      props.selected &&
      !['Leroy Merlin Trezor'].includes(props.selected.source),
    name: (props) =>
      props.selected &&
      props.selected.intervention &&
      props.selected.intervention.id
        ? 'Modifier l\'intervention'
        : 'Créer une intervention',
    icon: Add,
    link: (elem) => transferTo('intervention', true)({ selected: elem }),
    action: transferTo('intervention'),
  },
  {
    name: 'Appel Artisan',
    icon: Call,
    action: callAction,
    customVisible: (elem, props) => props?.selected?.currentArtisan?.telArtisan,
  },
  {
    name: 'Refuser la demande',
    icon: Cancel,
    sub: [
      {
        name: 'Annulation de la demande',
        action: callChangeStatus(D_REF, 'Client souhaite annuler'),
      },
      {
        name: 'Le client me rappel',
        action: callChangeStatus(D_REF, 'Le client me rappel'),
      },
      {
        name: 'Le client ne répond pas',
        action: callChangeStatus(D_REF, 'Client Injoignable'),
      },
      {
        name: 'Les délais sont trop courts',
        action: callChangeStatus(D_REF, 'Les délais sont trop courts'),
      },
      {
        name: 'Refus de prix',
        action: callChangeStatus(D_REF, 'Refus de prix'),
      },
      {
        name: 'Incompétent',
        action: callChangeStatus(D_REF, 'Incompétent'),
      },
      {
        name: 'Mauvais Numéro',
        action: callChangeStatus(D_REF, 'Mauvais Numéro', 'mail'),
      },
      {
        name: 'Déjà dans la base',
        action: callChangeStatus(D_REF, 'Déjà dans la base'),
      },
      {
        name: 'Autres',
        action: callChangeStatus(D_REF),
      }
    ],
  },
  {
    name: 'Programmer un rappel',
    icon: DateRange,
    action: (elem) => reminderAction(D_AR)(elem),
  },
  {
    name: 'Message Vocaux',
    icon: Voicemail,
    action: (elem) => {
      const { selected, users, selectedForCampaign } = elem;
      const usersActive = users.toJS().filter(e => e.act);

      const campaignsToSend =
        generateCampaignsToSend({ selectedForCampaign, selected });

      displaySoundsFromRingover(campaignsToSend, usersActive, 0, elem.user);
    },
  },
  {
    name: 'Campagnes d\'Appels',
    icon: SupportAgent,
    action: (elem) => {
      const { selected, users, selectedForCampaign } = elem;
      const usersActive = users.toJS().filter(e => e.act);

      const campaignsToSend =
        generateCampaignsToSend({ selectedForCampaign, selected });

      displaySoundsFromRingover(campaignsToSend, usersActive, 1, elem.user);
    }
  },
  {
    name: 'Gestion',
    icon: SettingsIcon,
    sub: [
      {
        name: 'Supprimer la demande',
        icon: Delete,
        action: cancelDemande,
        customVisible: (elem, props) =>
          permit(props.user, { key: 'deleteDemandes' }),
      },
      {
        name: 'Réactiver la demande',
        action: callChangeStatus(D_NT),
        icon: CheckCircle,
      },
      {
        name: 'Attribuer la demande',
        customVisible: (elem, props) =>
          permit(props.user, { key: 'attribueDemandes' }),
        icon: HowToReg,
        action: (props, demande) => {
          openAssignDialog({
            data: demande,
            collection: 'demandes',
            cb: sendMail });
        }
      },
    ]
  },
];

export default demandeActions;
